import React from "react";
import { Navbar } from "./views/Navbar/Navbar";
import { Outlet } from "react-router";
import Footer from "./views/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ScriptTagger from "./components/ScriptTagger/ScriptTagger";

export const Layout = ({ manifest }: any): JSX.Element => {
  return (
    <React.Fragment>
      <Navbar manifestData={manifest} />
      {manifest?.tagger?.length > 0
        ? manifest?.tagger.map((script: Record<string, any>) => (
            <ScriptTagger
              key={script?.id}
              id={script?.id}
              src={script?.src}
              async={script?.async}
              defer={script?.defer}
              type={script?.type}
            />
          ))
        : null}
      <Outlet />
      <ScrollToTop />
      <Footer manifestData={manifest} />
    </React.Fragment>
  );
};
