import { useRef, useState } from "react";
import * as S from "./styles";
import Color from "color";
import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Heading,
  Image,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import {
  SocialIcons,
  CustomSocialIcon,
} from "../../../components/SocialIcons/SocialIcons";
import { NavLink } from "react-router-dom";
import { SiteSearch } from "../../../components/SiteSearch/SiteSearch";

export const MobileNav = ({
  manifestData,
}: Record<string, any>): JSX.Element => {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const anchorList = manifestData?.["views"];
  const config: any = manifestData?.views?.navbar?.config;
  const [search, setSearch] = useState("");
  const [showSiteMap, setShowSiteMap] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | any>();
  //scrolling to offset for navBar height

  const handleMoveScreenMobile = () => {
    const moveScreenMobile = () => {
      scrollTo({ top: -300, behavior: "smooth" });
    };
    addEventListener("hashchange", moveScreenMobile);
    return () => {
      removeEventListener("hashchange", moveScreenMobile);
    };
  };

  // close modal and reset search when x is clicked
  const handleDrawerClose = () => {
    setSearch("");
    onClose();
  };

  // for search
  const handleSearchOpen = () => {
    setShowSiteMap(true);
    onOpen();
  };
  const handleSearchClose = () => {
    setShowSiteMap(false);
    onClose();
  };
  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];

  const color1 = Color(primaryColor).lightness(40);
  const subColor = color1.toString();
  const color2 = color1.lightness(30);
  const nestedColor = color2.toString();

  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.Nav
        id="mobileBar"
        primary={primaryColor}
        tertiary={tertiaryColor}
      >
        <Link
          id="homeImgLink"
          cursor="pointer"
          fontFamily="Roboto"
          fontWeight="500"
          textDecoration="none"
          position="fixed"
          href="/"
        >
          <Image
            src={images?.["titleImage"]?.["url"]}
            alt={images?.["titleImage"]?.["altText"]}
            title="Go to Homepage"
            maxHeight={"98px"}
            maxWidth="80%"
            marginTop="8px"
          />
        </Link>
        <IconButton
          position="fixed"
          right="60px"
          marginTop="5px"
          cursor="ponter"
          backgroundColor={primaryColor}
          color="#fff"
          size="lg"
          aria-label="mobile sitemap search"
          onClick={handleSearchOpen}
          ref={btnRef}
          fontSize="24px"
          icon={<SearchIcon fontSize="24px" />}
        />
        <IconButton
          position="fixed"
          right="10px"
          marginTop="5px"
          cursor="ponter"
          backgroundColor={primaryColor}
          color="#fff"
          size="lg"
          aria-label="mobile navigation button"
          onClick={() => onOpen()}
          ref={btnRef}
          fontSize="24px"
          icon={<HamburgerIcon fontSize="24px" />}
        />
        <nav>
          {showSiteMap ? (
            <Drawer
              aria-label="siteMap-searchBar"
              id="siteMap-searchBar"
              size="md"
              isOpen={isOpen}
              placement="right"
              onClose={handleSearchClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay id="siteMapDrawerOverlay" />

              <DrawerContent aria-label="mobileSiteMap">
                <DrawerHeader
                  id="SearchHeading"
                  aria-label="siteMap-searchHeading"
                  borderBottom="2px solid #c28400"
                  height="145px"
                  backgroundColor={primaryColor}
                >
                  <Text
                    color="white"
                    textAlign="center"
                    fontFamily="Fjalla One, sans-serif"
                    fontSize="2rem"
                  >
                    Search
                  </Text>
                  <InputGroup
                    id="Search"
                    height="60px"
                    marginTop="16px"
                  >
                    <Input
                      data-testid={"Search"}
                      aria-label="Search"
                      type="search"
                      size="md"
                      variant="outline"
                      color="#4A5568"
                      border="0px 16px 28px -1px rgba(14, 31, 53, 0.18)"
                      backgroundColor="#fff"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <InputRightElement pointerEvents="none">
                      <SearchIcon style={{ color: "#4A5568" }} />
                    </InputRightElement>
                  </InputGroup>
                </DrawerHeader>
                <DrawerBody
                  backgroundColor="#eeee"
                  display="flex"
                  flexDirection="column"
                >
                  <SiteSearch
                    onClose={onClose}
                    featuresObject={featuresList}
                    search={search}
                  />
                </DrawerBody>
                <DrawerCloseButton
                  id="siteMap-drawerCloseButton"
                  color="#fff"
                  onClick={handleDrawerClose}
                />
              </DrawerContent>
            </Drawer>
          ) : (
            <Drawer
              id="mobileNavFlyout"
              size="md"
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay id="mobileDrawerOverlay" />
              <DrawerContent aria-label="mobileNavigation">
                <DrawerCloseButton
                  id="drawerCloseButton"
                  color="#fff"
                />
                <DrawerHeader
                  id="DrawerHeader"
                  backgroundColor={primaryColor}
                />
                <DrawerBody
                  id="mobileNavigation"
                  color="#fff"
                  backgroundColor={primaryColor}
                >
                  <Link
                    key="homeLink"
                    id="homeLink"
                    pl="15px"
                    fontFamily="Roboto"
                    fontWeight="800"
                    color="#fff"
                    textDecorationLine="none"
                    href="/"
                  >
                    Home
                  </Link>
                  <Accordion
                    id="mobileAccordion"
                    width="100%"
                    pl="0"
                    pr="0"
                    allowMultiple
                    backgroundColor={primaryColor}
                    textColor="#fff"
                    borderTopWidth="1px"
                  >
                    {config.anchors?.map((anchor: any) => (
                      <AccordionItem
                        key={anchor + `-singleItem`}
                        id={anchor + `-singleItem`}
                        borderWidth="0"
                        padding="8px 16px"
                        fontWeight="700"
                        onClick={handleMoveScreenMobile()}
                      >
                        {/* single item */}
                        <a
                          key={anchorList[anchor]?.title + `-singleIn`}
                          id={anchorList[anchor]?.title + `-singleIn`}
                          href={`/#` + anchor}
                          style={{ width: "100%" }}
                          onClick={onClose}
                        >
                          <Box width="inherit">
                            {anchorList[anchor].anchorTitle}
                          </Box>
                        </a>
                      </AccordionItem>
                    ))}
                    {config.items?.map((item: any) =>
                      featuresList[item].type === "submenu" ? (
                        <AccordionItem
                          key={item + `-submenu`}
                          id={item + `-submenu`}
                          borderBottomWidth="1px"
                        >
                          <Heading>
                            <AccordionButton fontWeight="700">
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                              >
                                {featuresList[item].navHeader}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </Heading>
                          <AccordionPanel
                            backgroundColor={subColor}
                            onClick={onClose}
                          >
                            {featuresList[item]?.dropdown?.map(
                              (dropdownItem: any) =>
                                featuresList[dropdownItem]?.external ===
                                true ? (
                                  <Link
                                    key={dropdownItem}
                                    id={dropdownItem}
                                    textDecorationLine="none"
                                    href={featuresList[dropdownItem]?.url}
                                    isExternal
                                  >
                                    <Box
                                      width="inherit"
                                      p="1"
                                    >
                                      {featuresList[dropdownItem]?.title}
                                    </Box>
                                  </Link>
                                ) : (
                                  //internal link
                                  <Link
                                    key={dropdownItem}
                                    id={dropdownItem}
                                    textDecorationLine="none"
                                    as={NavLink}
                                    to={dropdownItem}
                                    onClick={onClose}
                                  >
                                    <Box
                                      width="inherit"
                                      p="1"
                                    >
                                      {featuresList[dropdownItem]?.title}
                                    </Box>
                                  </Link>
                                )
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      ) : featuresList[item].type === "megaMenu" ? (
                        <AccordionItem
                          key={item + `-megamenu`}
                          id={item + `-megamenu`}
                          borderBottomWidth="1px"
                        >
                          <Heading>
                            <AccordionButton
                              id={featuresList[item].navHeader}
                              aria-labelledby={featuresList[item].navHeader}
                            >
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                fontWeight="700"
                              >
                                {featuresList[item].navHeader}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </Heading>
                          {featuresList[item]?.dropdown?.map(
                            (dropdownItem: any) => (
                              <AccordionPanel
                                key={dropdownItem.columnHeader}
                                id={dropdownItem.columnHeader}
                                pl="0"
                                pr="0"
                                backgroundColor={subColor}
                              >
                                <AccordionItem border="0px">
                                  <Heading>
                                    <AccordionButton fontWeight="700">
                                      <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        ml="3"
                                      >
                                        {dropdownItem.columnHeader}
                                      </Box>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </Heading>

                                  {dropdownItem.items?.map((item: any) =>
                                    featuresList[item]?.external === true ? (
                                      //external link
                                      <AccordionPanel
                                        key={item + `-ex`}
                                        id={item + `-ex`}
                                        pl="0"
                                        pr="0"
                                        backgroundColor={nestedColor}
                                      >
                                        <Link
                                          textDecorationLine="none"
                                          href={featuresList[item]?.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={onClose}
                                          isExternal
                                        >
                                          <Box
                                            width="inherit"
                                            p="1"
                                            ml="8"
                                          >
                                            {featuresList[item]?.title}
                                          </Box>
                                        </Link>
                                      </AccordionPanel>
                                    ) : (
                                      //internal link
                                      <AccordionPanel
                                        key={item + `-ex`}
                                        id={item + `-ex`}
                                        pl="0"
                                        pr="0"
                                        backgroundColor={nestedColor}
                                      >
                                        <Link
                                          key={item + `-in`}
                                          id={item + `-in`}
                                          textDecorationLine="none"
                                          as={NavLink}
                                          to={item}
                                          onClick={onClose}
                                        >
                                          <Box
                                            width="inherit"
                                            p="1"
                                            ml="8"
                                          >
                                            {featuresList[item]?.title}
                                          </Box>
                                        </Link>
                                      </AccordionPanel>
                                    )
                                  )}
                                </AccordionItem>
                              </AccordionPanel>
                            )
                          )}
                        </AccordionItem>
                      ) : (
                        <AccordionItem
                          key={item + `-singleItem`}
                          id={item + `-singleItem`}
                          borderWidth="0"
                          padding="8px 16px"
                          fontWeight="700"
                        >
                          {/* single item */}

                          {featuresList[item]?.external === true ? (
                            // external link
                            <Link
                              key={featuresList[item]?.title + `-singleEx`}
                              id={featuresList[item]?.title + `-singleEx`}
                              textDecorationLine="none"
                              href={featuresList[item]?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={onClose}
                              isExternal
                            >
                              <Box width="inherit">
                                {featuresList[item]?.title}
                              </Box>
                            </Link>
                          ) : (
                            // internal link
                            <Link
                              key={featuresList[item]?.title + `-singleIn`}
                              id={featuresList[item]?.title + `-singleIn`}
                              textDecorationLine="none"
                              as={NavLink}
                              to={item}
                              onClick={onClose}
                            >
                              <Box width="inherit">
                                {featuresList[item].title}
                              </Box>
                            </Link>
                          )}
                        </AccordionItem>
                      )
                    )}
                  </Accordion>
                </DrawerBody>
                <DrawerFooter
                  data-testid={"mobileNavFooter"}
                  justifyContent="space-evenly"
                  backgroundColor={primaryColor}
                  transition="0.25s"
                  color="#f0f0f0"
                  alignItems="center"
                  padding="1rem"
                >
                  {config?.socialMedia?.map(
                    (item: {
                      icon: string;
                      url: string;
                      name: string;
                      customIcon: string;
                    }) => (
                      <Link
                        key={item.url}
                        id={item.url}
                        href={item.url}
                        fontFamily="Roboto"
                        fontWeight="500"
                        textDecoration="none"
                        borderTopColor="tertiaryColor"
                        backgroundColor="tertiaryColor"
                        onClick={onClose}
                      >
                        {item.customIcon
                          ? CustomSocialIcon(
                              item.customIcon,
                              "45px",
                              item?.name
                            )
                          : SocialIcons(item.icon, "45px")}
                      </Link>
                    )
                  )}
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )}
        </nav>
      </S.Nav>
    )
  );
};
