import React, { useEffect } from "react";

interface ScriptProps {
  id: string; // ID for the script tag
  src: string; // Source URL of the script
  async?: boolean; // Whether to load the script asynchronously
  defer?: boolean; // Whether to defer the script execution
  type?: string; // The type attribute of the script tag
}

const ScriptTagger: React.FC<ScriptProps> = ({
  id,
  src,
  async = true,
  defer = false,
  type = "text/javascript",
}) => {
  useEffect(() => {
    // Check if a script with the same ID already exists
    if (id && document.getElementById(id)) {
      console.warn(`Script with ID "${id}" already exists in the document.`);
      return undefined;
    }
    const script = document.createElement("script");
    script.id = id || ""; // Set the ID if provided
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.type = type;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      const existingScript = id ? document.getElementById(id) : script;
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [id, src, async, defer, type]);

  return null; // No UI rendering
};

export default ScriptTagger;
