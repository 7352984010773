import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route, Navigate } from "react-router-dom";
import { usePageTracking } from "./hooks/usePageTracking";
import { useManifestData } from "./hooks/useManifestData";
import { Helmet } from "react-helmet";
import { Suspense, useEffect } from "react";
import { Layout } from "./Layout";
import OCVManifest from "./OCVManifest";
import { HomePageLoading } from "./components/Loading/HomePageLoading";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import { useQueryClient } from "@tanstack/react-query";

export default function App(): JSX.Element {
  usePageTracking();

  const { data } = useManifestData();

  // if (data?.error) {
  //   return <ErrorPage message={data.error} />;
  // }
  const queryClient = useQueryClient();
  useEffect(() => {
    const handlePageReload = () => {
      queryClient.invalidateQueries(["manifest"]);
    };

    window.addEventListener("beforeunload", handlePageReload);
    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, [queryClient]);
  return (
    <ChakraProvider>
      <Suspense fallback={<HomePageLoading manifest={data} />}>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={data?.stylesheet?.images?.favicon?.url}
            sizes="48x48"
          />
        </Helmet>
        <Routes>
          {/* Redirect from /index.html to / */}
          <Route
            path="/index.html"
            element={
              <Navigate
                to="/"
                replace
              />
            }
          />
          <Route
            key="layout-route"
            path="/"
            element={<Layout manifest={data} />}
            errorElement={<ErrorPage manifest={data} />}
          >
            {/* buildMainPage function goes here */}
            <Route
              key="main-page-route"
              index
              element={data && OCVManifest.buildMainPage(data)}
              errorElement={<ErrorPage manifest={data} />}
            />
            {/* buildRoutes function goes here  */}
            {data && OCVManifest.buildRoutes(data)}
          </Route>
          {/* Add the ErrorPage route here */}
          <Route
            key="error-page-route"
            path="*"
            element={<ErrorPage manifest={data} />}
            errorElement={<ErrorPage manifest={data} />}
          />
        </Routes>
      </Suspense>
    </ChakraProvider>
  );
}
