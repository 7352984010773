import { ExternalLink, StyledLink, StyledAnchor } from "../Links/Links";
import * as S from "./styles";
import { Dropdown } from "./Dropdown/Dropdown";
import {
  SocialIcons,
  CustomSocialIcon,
} from "../../../components/SocialIcons/SocialIcons";
import {
  Image,
  InputGroup,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  InputRightElement,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "styled-components";
import { SearchIcon } from "@chakra-ui/icons";
import { SiteSearch } from "../../../components/SiteSearch/SiteSearch";
import { useState } from "react";

export const StyledHeading = styled.h1`
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export function DesktopNav({ manifestData }: Record<string, any>): JSX.Element {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const anchorList = manifestData?.["views"];
  const config: any = manifestData?.views?.navbar?.config;
  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colors = {
    primaryColor,
    tertiaryColor,
  };

  // close modal and reset search when x is clicked
  const handleModalClose = () => {
    setSearch("");
    onClose();
  };
  const scrollToAnchor = (anchor: any) => {
    const anchorElement = document.getElementById(anchor);

    if (anchorElement) {
      const navbarHeight = 10; // Set the height of your navbar here
      const offsetPosition = anchorElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.StyledNavbar
        $primary={colors.primaryColor}
        $tertiary={colors.tertiaryColor}
      >
        <S.NavDiv>
          <StyledLink to="/">
            <Image
              src={images?.["titleImage"]?.["url"]}
              alt={images?.["titleImage"]?.["altText"]}
              title="Go to Homepage"
              maxWidth={config?.imgWidth ? config?.imgWidth : "75px"}
              verticalAlign="middle"
              display="inline"
            />
            {config?.title && (
              <StyledHeading
                dangerouslySetInnerHTML={{ __html: config?.title }}
              />
            )}
          </StyledLink>
        </S.NavDiv>
        <S.Navigation aria-labelledby="primaryMenu">
          <S.NavList
            id="primaryMenu"
            role="menu"
          >
            {config.anchors?.map((anchor: any, index: number) => (
              <S.NavListItem
                key={anchor + index}
                $primary={colors.primaryColor}
                role="menuitem"
                onClick={() => scrollToAnchor(anchor)}
              >
                <StyledAnchor tabIndex={0}>
                  {anchorList[anchor].anchorTitle}
                </StyledAnchor>
              </S.NavListItem>
            ))}
            {config.items?.map((item: any, index: number) => (
              <S.NavListItem
                $type={featuresList[item].type}
                key={item + index}
                $primary={colors.primaryColor}
                role="menuitem"
              >
                {featuresList[item].type === "submenu" ||
                featuresList[item].type === "megaMenu" ? (
                  <Dropdown
                    featuresList={featuresList}
                    item={item}
                    index={index}
                    colors={colors}
                    tabIndex={0}
                  />
                ) : featuresList[item]?.external === true ? (
                  <ExternalLink
                    href={featuresList[item].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </ExternalLink>
                ) : (
                  <StyledLink
                    to={item}
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </StyledLink>
                )}
              </S.NavListItem>
            ))}
          </S.NavList>
        </S.Navigation>
        <S.NavDiv>
          <IconButton
            marginTop="5px"
            cursor="ponter"
            backgroundColor={primaryColor}
            color="#fff"
            size="lg"
            aria-label="desktop sitemap search"
            onClick={onOpen}
            fontSize="22px"
            icon={<SearchIcon fontSize="22px" />}
          />
          {config?.socialMedia?.map(
            (
              item: {
                icon: string;
                url: string;
                name: string;
                customIcon: string;
              },
              index: number
            ) => (
              <ExternalLink
                href={item.url}
                key={item.icon + index}
                target="_blank"
                aria-label={`Link to ${item?.name}`}
              >
                {item.customIcon
                  ? CustomSocialIcon(item.customIcon, "22px", item?.name)
                  : SocialIcons(item.icon, "22px")}
              </ExternalLink>
            )
          )}
        </S.NavDiv>
        <Modal
          onClose={onClose}
          closeOnEsc
          size="4xl"
          isOpen={isOpen}
          allowPinchZoom
        >
          <ModalOverlay
            bg="none"
            backdropFilter="blur(10px)"
          />
          <ModalContent
            aria-label="SearchModal"
            borderRadius="16px"
            backgroundColor={primaryColor}
          >
            <ModalHeader
              id="SearchHeading"
              aria-label="siteMap-searchHeading"
              borderBottom="4px solid #c28400"
              height="145px"
              borderTopRadius="16px"
              backgroundColor={primaryColor}
            >
              <Text
                color="white"
                textAlign="center"
                fontFamily="Fjalla One, sans-serif"
                fontSize="2rem"
              >
                Search
              </Text>
              <InputGroup
                id="Search"
                height="60px"
                marginTop="16px"
              >
                <Input
                  data-testid={"Search"}
                  aria-label="Search"
                  type="search"
                  size="md"
                  variant="outline"
                  color="#4A5568"
                  backgroundColor="#fff"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputRightElement pointerEvents="none">
                  <SearchIcon style={{ color: "#4A5568" }} />
                </InputRightElement>
              </InputGroup>
            </ModalHeader>
            <ModalCloseButton
              style={{ color: "white" }}
              onClick={handleModalClose}
            />
            <ModalBody
              id="ContentBody"
              borderBottomRadius="16px"
              backgroundColor="white"
            >
              <SiteSearch
                onClose={onClose}
                featuresObject={featuresList}
                search={search}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </S.StyledNavbar>
    )
  );
}
