import { useRef } from "react";
import ReactImageGallery from "react-image-gallery";

interface Args {
  galleryRef: React.RefObject<ReactImageGallery>;
  handleFullscreen: () => void;
  handleScreenChange: (isFullScreen: boolean) => void;
}

export const useImageGallery = (): Args => {
  const galleryRef: React.RefObject<ReactImageGallery> = useRef(null);

  function handleFullscreen() {
    if (galleryRef.current === null) {
      console.error("NULL REF");
    } else {
      galleryRef.current.fullScreen();
      galleryRef.current.pause();
    }
  }

  function handleScreenChange(isFullScreen: boolean) {
    if (galleryRef.current === null) {
      console.error("NULL REF");
    } else {
      if (!isFullScreen) {
        galleryRef.current.play();
      }
    }
  }

  return { galleryRef, handleFullscreen, handleScreenChange };
};
