/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import ImageGallery from "react-image-gallery";
import { useImageGallery } from "../../hooks/useImageGallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css";
import { useMediaQuery } from "@chakra-ui/react";

interface Media {
  mediaURL?: string;
  mediaAlt?: string;
  mediaLink?: string;
  mediaMobile?: string;
}

export default function Slider({
  sliderConfig,
  anchorID,
}: Record<string, any>): JSX.Element {
  const [isLessThan768px] = useMediaQuery("(max-width: 768px)");
  const { galleryRef } = useImageGallery();
  function getCurrentIndex() {
    if (galleryRef.current) {
      return galleryRef.current.getCurrentIndex();
    }
    return null;
  }
  const images =
    sliderConfig?.media?.map((media: Media) => {
      return {
        original: media.mediaURL,
        originalAlt: media.mediaAlt,
        originalClass: "SliderDiv",
        originalTitle: media.mediaLink,
        link: media.mediaLink,
        mobile: media.mediaMobile,
      };
    }) ?? [];

  const renderImageItem = (image: Record<string, any>) => (
    <img
      style={{ width: "100%" }}
      src={isLessThan768px && image.mobile ? image.mobile : image.original}
      alt={image.originalAlt}
      title={image.link ? image.link : undefined}
    />
  );

  const ocvImages = images.map((image: Record<string, any>) => ({
    ...image,
    renderItem: () => renderImageItem(image),
  }));

  // Custom controls rendering
  const renderCustomControls = () => {
    const currentIndex = getCurrentIndex(); // Get the current index from the ref
    const currentImage =
      currentIndex !== null && currentIndex !== undefined
        ? images[currentIndex]
        : null;

    // Only render custom controls if there is a valid link
    if (!currentImage?.link) return null;

    return (
      <button
        className="image-gallery-custom-action"
        tabIndex={0} // Make it focusable
        onClick={() =>
          window.open(currentImage.link, "_blank", "noopener,noreferrer")
        }
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            window.open(currentImage.link, "_blank", "noopener,noreferrer");
          }
        }}
        aria-label={`Open link to ${currentImage.originalAlt || "this image"}`}
      >
        Open Link
      </button>
    );
  };

  function handleClick(images: any, currentIndex: number | null): void {
    if (currentIndex !== null && images[currentIndex]?.link) {
      window.open(images[currentIndex].link, "_blank", "noopener,noreferrer");
    }
  }
  return (
    <section
      id={anchorID}
      data-testid="slider-section"
      className="SliderSection"
    >
      <ImageGallery
        ref={galleryRef}
        items={ocvImages}
        showPlayButton={ocvImages.length > 1}
        showBullets={ocvImages.length > 1}
        showFullscreenButton={false}
        autoPlay={true}
        slideInterval={sliderConfig.interval ?? 5000}
        showThumbnails={false}
        showNav={false}
        lazyLoad={true}
        onClick={() => handleClick(images, getCurrentIndex())}
        renderCustomControls={renderCustomControls} // Use the new renderCustomControls method
      />
    </section>
  );
}
