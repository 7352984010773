import { lazy, Suspense, Fragment } from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import OCVManifestModel from "./OCVManifestModel";
import Slider from "./views/Slider/Slider";
import SliderMultiMedia from "./views/SliderMultiMedia/SliderMultiMedia";
import PageLoading from "./components/Loading/PageLoading";

const Iframe = lazy(
  () => import(/* webpackChunkName: 'webview' */ "react-iframe")
);
const CollapsableBanner = lazy(
  () =>
    import(
      /* webpackChunkName: 'collapsableBanner' */ "./views/CollapsableBanner/CollapsableBanner"
    )
);
const FeatureBar = lazy(
  () =>
    import(/* webpackChunkName: 'featureBar' */ "./views/FeatureBar/FeatureBar")
);
const PossibilityBox = lazy(
  () =>
    import(
      /* webpackChunkName: 'possibilityBox' */ "./views/PossibilityBox/PossibilityBox"
    )
);
const TwoColumnView = lazy(
  () =>
    import(
      /* webpackChunkName: 'twoColumnView' */ "./views/TwoColumnView/TwoColumnView"
    )
);
const TwoColumnButton = lazy(
  () =>
    import(
      /* webpackChunkName: 'twoColumnButton' */ "./views/TwoColumnButton/TwoColumnButton"
    )
);
const MonthlyFeature = lazy(
  () =>
    import(
      /* webpackChunkName: 'monthlyFeature' */ "./views/MonthlyFeature/MonthlyFeature"
    )
);
const OCVPage = lazy(
  () => import(/* webpackChunkName: 'page' */ "./features/OCVPage/OCVPage")
);
const OCVPhotoGallery = lazy(
  () =>
    import(
      /* webpackChunkName: 'photoGallery' */ "./features/OCVPhotoGallery/OCVPhotoGallery"
    )
);
const OCVContacts = lazy(
  () =>
    import(
      /* webpackChunkName: 'contacts' */ "./features/OCVContacts/OCVContacts"
    )
);
const SiteMap = lazy(
  () => import(/* webpackChunkName: 'sitemap' */ "./features/SiteMap/SiteMap")
);

const Breadcrumb = lazy(
  () =>
    import(
      /* webpackChunkName: 'breadcrumb' */ "./components/Breadcrumb/Breadcrumb"
    )
);
const PageTitle = lazy(
  () =>
    import(
      /* webpackChunkName: 'pageTitle' */ "./components/PageTitle/PageTitle"
    )
);
const OCVCalendar = lazy(
  () =>
    import(
      /* webpackChunkName: 'calendar' */ "./features/OCVCalendar/OCVCalendar"
    )
);
const OCVMap = lazy(
  () => import(/* webpackChunkName: 'map' */ "./features/OCVMap/OCVMap")
);
const OCVSexOffendersMap = lazy(
  () =>
    import(
      /* webpackChunkName: 'sexOffendersMap' */ "./features/OCVSexOffendersMap/OCVSexOffendersMap"
    )
);
const SocialBar = lazy(
  () =>
    import(/* webpackChunkName: 'socialBar' */ "./views/SocialBar/SocialBar")
);
const PageView = lazy(
  () => import(/* webpackChunkName: 'pageView' */ "./views/PageView/PageView")
);
const BlogCarousel = lazy(
  () =>
    import(
      /* webpackChunkName: 'blogCarousel' */ "./views/OCVBlogCarousel/BlogCarousel"
    )
);
const OCVBlogGridWidget = lazy(
  () =>
    import(
      /* webpackChunkName: 'blogGridWidget' */ "./views/OCVBlogGridWidget/OCVBlogGridWidget"
    )
);
const BlogFAQ = lazy(
  () =>
    import(/* webpackChunkName: 'blogFAQ' */ "./views/OCVBlogFAQ/OCVBlogFAQ")
);
const TwoColumnVideo = lazy(
  () =>
    import(
      /* webpackChunkName: 'twoColumnVideo' */ "./views/TwoColumnVideo/TwoColumnVideo"
    )
);
const ContainerBlogFeed = lazy(
  () =>
    import(
      /* webpackChunkName: 'containerBlogFeed' */ "./features/BlogFeedListWidgets/ContainerBlogFeed"
    )
);

const OCVBlogGridPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'blogGrid' */ "./features/OCVBlogGridPage/OCVBlogGridPage"
    )
);
const NewsDigest = lazy(
  () =>
    import(/* webpackChunkName: 'newsDigest' */ "./views/NewsDigest/NewsDigest")
);
const DigestContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'newsDigestList' */ "./features/NewsDigestList/DigestContainer"
    )
);
const OCVVideoPlayer = lazy(
  () =>
    import(
      /* webpackChunkName: 'video' */ "./views/OCVVideoPlayer/OCVVideoPlayer"
    )
);
const OCVContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'container' */ "./features/OCVContainer/OCVContainer"
    )
);
const FormWrapper = lazy(
  () => import(/* webpackChunkName: 'form' */ "./features/Form/OCVFormWrapper")
);
const YouTubePlayer = lazy(
  () =>
    import(
      /* webpackChunkName: 'youTubePlayer' */ "./features/YouTubePlayer/YouTubePlayer"
    )
);
const GoogleDrive = lazy(
  () =>
    import(
      /* webpackChunkName: 'googleDrive' */ "./views/GoogleDrive/GoogleDrive"
    )
);
const BlogCard = lazy(
  () => import(/* webpackChunkName: 'blogCard' */ "./views/BlogCard/BlogCard")
);
const Label = lazy(
  () => import(/* webpackChunkName: 'label' */ "./views/Label/Label")
);
const EventsView = lazy(
  () => import(/* webpackChunkName: 'label' */ "./views/EventsView/EventsView")
);
const AtAGlance = lazy(
  () =>
    import(/* webpackChunkName: 'atAGlance' */ "./views/AtAGlance/AtAGlance")
);
const Welcome = lazy(
  () => import(/* webpackChunkName: 'welcome' */ "./views/Welcome/Welcome")
);
const OCVSearch = lazy(
  () =>
    import(
      /* webpackChunkName: 'search' */ "./components/AlgoliaSearch/SearchPage"
    )
);
const ContactCard = lazy(
  () =>
    import(
      /* webpackChunkName: 'contactCard' */ "./views/ContactCard/ContactCard"
    )
);
const ChatContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'chatContainer' */ "./views/ChatAgents/ChatContainer"
    )
);
const SocialMediaView = lazy(
  () =>
    import(
      /* webpackChunkName: 'socialMediaView' */ "./views/SocialMediaView/SocialMediaView"
    )
);
const LinkLibrary = lazy(
  () =>
    import(
      /* webpackChunkName: 'linkLibrary' */ "./views/LinkLibrary/LinkLibrary"
    )
);
const FileGallery = lazy(
  () =>
    import(
      /* webpackChunkName: 'fileGallery' */ "./views/FileGallery/FileGallery"
    )
);
interface OCVRoute {
  path: string;
  exact: boolean;
  page: JSX.Element[];
}

export default class OCVManifest {
  manifestData!: OCVManifestModel;

  static buildMainPage(manifestData: Record<string, any>): JSX.Element[] {
    const { views } = manifestData;
    const mainPage: JSX.Element[] = [];
    for (let i = 0; i < manifestData.homeOrder?.length; i++) {
      const view = views[manifestData.homeOrder[i]];

      // Skip this iteration if view or view.type is null or undefined
      if (!view?.type) {
        continue;
      }

      switch (view.type) {
        case "slider":
          mainPage.push(
            <Slider
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              sliderConfig={view.config}
            />
          );
          break;
        case "sliderMultiMedia":
          mainPage.push(<SliderMultiMedia sliderConfig={view.config} />);
          break;
        case "video":
          mainPage.push(
            <OCVVideoPlayer
              key={manifestData.homeOrder[i]}
              config={view.config}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
            />
          );
          break;
        case "possibilityBox":
          mainPage.push(
            <PossibilityBox
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              viewData={view}
              config={view["config"]}
              manifestData={manifestData}
            />
          );
          break;
        case "chat":
          mainPage.push(
            <ChatContainer
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
            />
          );
          break;
        case "monthlyFeature":
          mainPage.push(
            <MonthlyFeature
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
            />
          );
          break;
        case "twoColumnView":
          mainPage.push(
            <TwoColumnView
              key={manifestData.homeOrder[i]}
              viewData={view}
              config={view["config"]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
            />
          );
          break;
        case "twoColumnButton":
          mainPage.push(
            <TwoColumnButton
              key={manifestData.homeOrder[i]}
              viewData={view}
              config={view["config"]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
            />
          );
          break;
        case "twoColumnVideo":
          mainPage.push(
            <TwoColumnVideo
              key={manifestData.homeOrder[i]}
              viewData={view}
              config={view["config"]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
            />
          );
          break;
        case "featureBar":
          mainPage.push(
            <FeatureBar
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              viewData={view}
              config={view.config}
              manifestData={manifestData}
            />
          );
          break;
        case "collapsableBanner":
          mainPage.push(
            <CollapsableBanner
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              viewData={view}
              config={view.config}
              manifestData={manifestData}
            />
          );
          break;
        case "socialBar":
          mainPage.push(
            <SocialBar
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "label":
          mainPage.push(
            <Label
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "eventsView":
          mainPage.push(
            <EventsView
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "pageView":
          mainPage.push(
            <PageView
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "newsDigest":
          mainPage.push(
            <NewsDigest
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "blogCarousel":
          mainPage.push(
            <BlogCarousel
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "blogGrid":
          mainPage.push(
            <OCVBlogGridWidget
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "blogFAQ":
          mainPage.push(
            <BlogFAQ
              key={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
              link={view.url}
              anchorID={manifestData.homeOrder[i]}
              showSearch={view.config?.showSearch}
              showSort={view.config?.showSort}
              sortOrder={view.config?.sansort}
              showHeading={true}
              queryKey={`queryBlogFAQ` + manifestData.homeOrder[i]}
            />
          );
          break;
        case "blogCard":
          mainPage.push(
            <BlogCard
              key={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        case "atAGlance":
          mainPage.push(
            <AtAGlance
              key={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
              link={view.url}
              anchorID={manifestData.homeOrder[i]}
              queryKey={`queryAtAGlance` + manifestData.homeOrder[i]}
            />
          );
          break;
        case "welcome":
          mainPage.push(
            <Welcome
              key={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
              link={view.url}
              showCaption={view.config.showCaption}
              showSubTitle={view.config.showSubTitle}
              anchorID={manifestData.homeOrder[i]}
              queryKey={`queryWelcome` + manifestData.homeOrder[i]}
            />
          );
          break;
        case "contactCard":
          mainPage.push(
            <ContactCard
              key={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
              anchorID="contactCard"
            />
          );
          break;
        case "socialMediaView":
          mainPage.push(
            <SocialMediaView
              key={manifestData.homeOrder[i]}
              viewData={view}
              anchorID="socialMediaView"
              manifestData={manifestData}
            />
          );
          break;
        case "linkLibrary":
          mainPage.push(
            <LinkLibrary
              key={manifestData.homeOrder[i]}
              anchorID={manifestData.homeOrder[i]}
              manifestData={manifestData}
              viewData={view}
            />
          );
          break;
        case "googleDrive":
          mainPage.push(
            <GoogleDrive
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
              folderId={view.folderId}
              layout={view.config ? view.config.layout : "list"}
            />
          );
          break;
        case "fileGallery":
          mainPage.push(
            <FileGallery
              key={manifestData.homeOrder[i]}
              viewData={view}
              manifestData={manifestData}
              anchorID={manifestData.homeOrder[i]}
            />
          );
          break;
        default:
          break;
      }
    }
    return mainPage;
  }

  static buildWebPage(
    manifestData: Record<string, any>,
    component: JSX.Element,
    title: string
  ): JSX.Element[] {
    const { views } = manifestData;
    const webPage: JSX.Element[] = [];
    webPage.push(
      <Helmet key={`helmet+${title}`}>
        <title>
          {title} | {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
    );
    for (let i = 0; i < manifestData.baseLayout.length; i++) {
      if (manifestData.baseLayout[i] === "{component}") {
        webPage.push(
          <PageTitle
            key={`Page ` + title}
            title={title}
          />,
          <Breadcrumb
            key={`Breadcrumb ` + title}
            title={title}
          />,
          component
        );
      } else {
        const view = views[manifestData.baseLayout[i]];
        switch (view.type) {
          case "slider":
            webPage.push(
              <Slider
                key={title + manifestData.baseLayout[i]}
                sliderConfig={view.config}
              />
            );
            break;
          case "sliderMultiMedia":
            webPage.push(<SliderMultiMedia sliderConfig={view.config} />);
            break;
          case "video":
            webPage.push(
              <OCVVideoPlayer
                key={manifestData.baseLayout[i]}
                config={view.config}
              />
            );
            break;
          case "collapsableBanner":
            webPage.push(
              <CollapsableBanner
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "socialBar":
            webPage.push(
              <SocialBar
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "label":
            webPage.push(
              <Label
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "eventsView":
            webPage.push(
              <EventsView
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "pageView":
            webPage.push(
              <PageView
                key={title + manifestData.baseLayout[i]}
                manifestData={manifestData}
                viewData={view}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "blogCarousel":
            webPage.push(
              <BlogCarousel
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "blogGrid":
            webPage.push(
              <OCVBlogGridWidget
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={view}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "googleDrive":
            webPage.push(
              <GoogleDrive
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
                folderId={view.folderId}
                layout={view.config ? view.config.layout : "list"}
              />
            );
            break;
          case "fileGallery":
            webPage.push(
              <FileGallery
                key={title + manifestData.baseLayout[i]}
                viewData={view}
                manifestData={manifestData}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "blogFAQ":
            webPage.push(
              <BlogFAQ
                key={manifestData.baseLayout[i]}
                viewData={view}
                link={view.url}
                anchorID={manifestData.baseLayout[i]}
                showSearch={view.config?.showSearch}
                showSort={view.config?.showSort}
                sortOrder={view.config?.sort}
                showHeading={false}
                queryKey={`queryBlogFAQ` + manifestData.baseLayout[i]}
              />
            );
            break;
          case "blogCard":
            webPage.push(
              <BlogCard
                key={manifestData.baseLayout[i]}
                manifestData={manifestData}
                viewData={view}
                anchorID={manifestData.baseLayout[i]}
              />
            );
            break;
          case "contactCard":
            webPage.push(
              <ContactCard
                key={manifestData.baseLayout[i]}
                manifestData={manifestData}
                viewData={view}
                anchorID="contactCard"
              />
            );
            break;
          case "socialMediaView":
            webPage.push(
              <SocialMediaView
                key={manifestData.baseLayout[i]}
                viewData={view}
                anchorID="socialMediaView"
              />
            );
            break;
          case "linkLibrary":
            webPage.push(
              <LinkLibrary
                key={manifestData.homeOrder[i]}
                anchorID={manifestData.homeOrder[i]}
                manifestData={manifestData}
                viewData={view}
              />
            );
            break;
          default:
            webPage.push(
              <p>
                &quot;The component you requested ({view.type}) is not in this
                build function.&quot;
              </p>
            );
            break;
        }
      }
    }
    return webPage;
  }

  static buildPagesFromFeatures(manifestData: Record<string, any>): OCVRoute[] {
    const { features } = manifestData;
    const featureKeys = Object?.keys(features);
    const response: OCVRoute[] = [];
    for (const key of featureKeys) {
      const feature = features[key];
      switch (feature.type) {
        case "container":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVContainer
                config={feature.config}
                manifestData={manifestData}
                key={`${key}container`}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "page":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVPage
                viewData={feature}
                key={`${key}page`}
                link={feature.url}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "photoGallery":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVPhotoGallery
                key={`${key}photo-gallery`}
                link={feature.url}
                config={feature.config}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "form":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <FormWrapper
                config={feature.config}
                formID={feature.formID || feature.formId}
                link={feature.url}
                headerText={feature.headerText && feature.leaderText}
                key={`${key}form`}
                submissionText={
                  feature.submissionText
                    ? feature.submissionText
                    : "This form has been submitted successfully. Please refresh the page to submit this form again."
                }
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "contacts":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVContacts
                manifest={manifestData}
                viewData={feature}
                link={feature?.url}
                key={`${key}contacts`}
                route={key}
              />,
              feature.title
            ),
          });
          break;
        case "sitemap":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <SiteMap
                manifest={manifestData}
                viewData={feature}
                key={`${key}-sitemap`}
              />,
              feature.title
            ),
          });
          break;
        case "calendar":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVCalendar
                viewData={feature.config}
                key={`${key}-calendar`}
                link={feature.url}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "map":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVMap
                config={feature.config}
                key={`${key}-map`}
                link={feature.url}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "sexOffendersMap":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVSexOffendersMap
                manifestData={manifestData}
                config={feature.config}
                anchorID={key}
                key={`${key}-offenderMap`}
                link={feature.url}
              />,
              feature.title
            ),
          });
          break;
        case "webview":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <Iframe
                position="relative"
                key={`${key}webview`}
                url={feature.url ? feature.url : "https://www.google.com"}
                width="100%"
                height="700px"
                id={key}
              />,
              feature.title
            ),
          });
          break;
        case "youTubePlayer":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <YouTubePlayer
                key={`${key}-YouTubePlayer`}
                playlistID={feature["playlistID"]}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "googleDrive":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <GoogleDrive
                key={`${key}-GoogleDrive`}
                folderId={feature.folderId}
                layout={feature.config ? feature.config.layout : "list"}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "fileGallery":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <FileGallery
                key={`${key}-fileGallery`}
                viewData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogGridPage":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVBlogGridPage
                key={`${key}blogGridPage`}
                url={feature.url}
                viewData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogGrid":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <OCVBlogGridWidget
                key={`${key}buttonBar`}
                manifestData={feature}
                viewData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "blogList":
        case "feedList":
        case "searchOnly":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <ContainerBlogFeed
                key={`${key}blog`}
                route={key}
                manifest={manifestData}
                manifestData={feature}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
        case "newsDigestList":
          response.push({
            path: `${key}/*`,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <DigestContainer
                route={key}
                key={`${key}newsDigestList`}
                viewData={feature}
                manifestData={manifestData}
              />,
              feature.title
            ),
          });
          break;
        case "blogFAQ":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <BlogFAQ
                key={`${key}blogFAQ`}
                viewData={feature}
                showSearch={feature.config.showSearch}
                showSort={feature.config.showSort}
                sortOrder={feature.config.sort}
                link={feature.url}
                anchorID={key}
                queryKey={`queryBlogFAQ` + key}
              />,
              feature.title
            ),
          });
          break;
        case "linkLibrary":
          response.push({
            path: key,
            exact: true,
            page: this.buildWebPage(
              manifestData,
              <LinkLibrary
                config={feature.config}
                manifestData={manifestData}
                viewData={feature}
                key={`${key}-linkLibrary`}
                anchorID={key}
              />,
              feature.title
            ),
          });
          break;
      }
    }
    return response;
  }

  static buildRoutes(manifestData: Record<string, any>): JSX.Element[] {
    const pages = this.buildPagesFromFeatures(manifestData);
    return pages.map((route: OCVRoute, index: number) => {
      const uniqueKey = `${route.path}-${index}`;
      return (
        <Fragment key={`fragment-${uniqueKey}`}>
          <Route
            key={`route-${uniqueKey}`}
            path={`${route.path}`}
            element={
              <Suspense
                fallback={
                  <div key={`fallback-${uniqueKey}`}>
                    {route.path}
                    <PageLoading />
                  </div>
                }
              >
                {route.page}
              </Suspense>
            }
          />
          <Route
            key={`route-ocvSearch-${uniqueKey}`}
            path="/ocvSearch"
            element={<OCVSearch />}
          />
        </Fragment>
      );
    });
  }
}
