import { Link, Text, VStack, Box } from "@chakra-ui/react";

export function SiteSearch({
  featuresObject,
  search,
  onClose,
}: Readonly<Record<string, any>>): JSX.Element {
  const handleDataProcessing = (featuresObject: any, search: string) => {
    // Step 1: Keep objects that have titles and do not have removeFromSiteMap
    const filteredData = Object.entries(featuresObject).filter(
      ([, value]: any) => value.title && value.removeFromSiteMap !== true
    );

    // Step 2: Sort the remaining objects alphabetically by title
    const sortByTitle = ([, valueA]: any, [, valueB]: any) =>
      valueA.title.localeCompare(valueB.title);

    // Use the sorting function to sort the filteredData array
    const sortedData = [...filteredData].sort(sortByTitle);

    // Step 3: If searchQuery is not empty, filter by matching titles
    const searchedData = search
      ? sortedData.filter(([, value]: any) =>
          value.title.toLowerCase().includes(search.toLowerCase())
        )
      : sortedData;

    // Step 4: Return the results as an array of [key, value]
    return searchedData;
  };

  const websiteFeatures = handleDataProcessing(featuresObject, search);

  if (websiteFeatures.length == 0) {
    return <Text>No Records Found.</Text>;
  }
  return (
    <VStack
      maxWidth="1100px"
      width="100%"
      justifyContent="center"
      alignContent="center"
    >
      {websiteFeatures?.map(([key, selectedFeature]: any) => (
        <Box
          key={`SiteSearch ${key}` + selectedFeature?.title}
          style={{
            height: "55px",
            width: "100%",
            borderRadius: "0.375rem",
            boxShadow: "0px 8px 14px -1px rgba(14, 31, 53, 0.18)",
            backgroundColor: "#ffff",
            padding: "16px",
          }}
          _hover={{
            borderWidth: "1px",
            borderColor: "#cdcdcd",
            backgroundColor: "#EDF2F7!important",
          }}
        >
          {selectedFeature?.external ? (
            <Link
              href={selectedFeature?.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClose}
            >
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                {selectedFeature?.title}
              </Text>
            </Link>
          ) : (
            <a
              id={selectedFeature?.title}
              href={`/${key}`}
              onClick={onClose}
            >
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                {selectedFeature?.title}
              </Text>
            </a>
          )}
        </Box>
      ))}
    </VStack>
  );
}
