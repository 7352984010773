import "./polyfills";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./App";
import SmartBanner from "react-smartbanner";
import "../node_modules/react-smartbanner/dist/main.css";
import "./index.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/ErrorPage/ErrorPage";

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});
// Helper function to determine if SmartBanner should render
const shouldDisplaySmartBanner = () => {
  const iosMeta = document.querySelector(
    'meta[name="apple-itunes-app"]'
  ) as HTMLMetaElement;
  const androidMeta = document.querySelector(
    'meta[name="google-play-app"]'
  ) as HTMLMetaElement;

  // Check if both meta tags exist and have valid non-empty content
  const iosHasValidContent = iosMeta && iosMeta.content.trim() !== "";
  const androidHasValidContent =
    androidMeta && androidMeta.content.trim() !== "";

  return iosHasValidContent && androidHasValidContent;
};
root.render(
  <ErrorBoundary fallback={<ErrorPage />}>
    <StrictMode>
      {/* https://patw0929.github.io/react-smartbanner/ */}
      {shouldDisplaySmartBanner() && (
        <SmartBanner
          daysHidden={7}
          daysReminder={30}
          title={process.env.REACT_APP_TITLE}
          button={"DOWNLOAD"}
          price={{ ios: "FREE", android: "FREE" }}
          position={"top"}
        />
      )}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StrictMode>
  </ErrorBoundary>
);
